import React from 'react';
import ScriptLoader from 'react-script-loader-hoc';

const withScriptLoader = (Component: React.FC, scripts: string[]): React.FC => {
  const Wrapper = ({
    scriptsLoadedSuccessfully,
  }: {
    scriptsLoadedSuccessfully: boolean;
  }) => {
    if (!scriptsLoadedSuccessfully) return <div />;
    return <Component />;
  };
  return ScriptLoader(...scripts)(Wrapper);
};

export default withScriptLoader;
