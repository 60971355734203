import React from 'react';
import { Box, Grid, Link, Text } from 'theme-ui';
import Image from '../../atoms/Image';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';
import CmsContent from '../../molecules/CmsContent';
import { CmsContentType } from '../../molecules/CmsContent/enums';
import HTMLWrapper from '../../molecules/HTMLWrapper';
import { ArticleLayoutProps } from './types';

const ArticleLayout: React.FC<ArticleLayoutProps> = ({
  date,
  title,
  subtitle,
  author,
  image,
  content,
}) => {
  const { name, image: authorImage, email } = author;

  return (
    <Box as="article" variant="articleLayout.wrapper">
      <RevealAnimationWrapper>
        <Grid as="section" variant="articleLayout.grid">
          <Box variant="articleLayout.heading">
            <Text as="p" variant="articleLayout.heading.date">
              <time dateTime={date}>{date}</time>
            </Text>
            <Text as="h1" variant="articleLayout.heading.title">
              {title}
            </Text>
            {!!subtitle && (
              <Text as="h2" variant="articleLayout.heading.subtitle">
                {subtitle}
              </Text>
            )}
          </Box>
        </Grid>
      </RevealAnimationWrapper>
      <Grid as="section" variant="articleLayout.grid">
        <Box as="aside" variant="articleLayout.aside">
          <Box variant="articleLayout.author">
            <RevealAnimationWrapper>
              <Box variant="articleLayout.author.media">
                <Image {...authorImage} ratio={252 / 164} />
              </Box>
              <Text as="h3" variant="articleLayout.author.name">
                {name}
              </Text>
              {email ? (
                <Link
                  variant="articleLayout.author.email"
                  href={`mailto:${email}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <HTMLWrapper html={email} />
                </Link>
              ) : (
                <></>
              )}
            </RevealAnimationWrapper>
          </Box>
        </Box>
        <Box variant="articleLayout.content">
          {!!image && (
            <RevealAnimationWrapper>
              <Box variant="articleLayout.content.media">
                <Image {...image} ratio={16 / 9} />
              </Box>
            </RevealAnimationWrapper>
          )}
          <CmsContent type={CmsContentType.article}>{content}</CmsContent>
        </Box>
      </Grid>
    </Box>
  );
};

export default ArticleLayout;
