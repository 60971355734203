import React, { useCallback, useMemo } from 'react';
import { Box, Container } from 'theme-ui';
import InnerHTML from 'dangerously-set-html-content';
import noop from 'lodash/noop';
import RevealAnimationWrapper from '../components/atoms/RevealAnimationWrapper';
import ArticleLayout from '../components/Layout/article';
import ArticlesSection from '../components/organisms/ArticlesSection';
import { InsightTemplateProps } from '../components/pages/insights/types';
import PageSection from '../components/pages/Section';
import {
  getInsightAuthor,
  getInsightsItems,
  getPostImage,
  getDate,
  stripTags,
  getPostContent,
  findContentScript,
} from '../components/pages/insights/utils';
import SEO from '../components/atoms/SEO';
import withScriptLoader from '../hocs/withScriptLoader';
import InfoBanner from '../components/organisms/InfoBanner';
import { infoBannerItems } from '../components/pages/contact/consts';
import useScript from '../hooks/useScript';

const HBSPT_NAME = 'js.hsforms.net';
const HBSPT_JS_PATH = 'https://js.hsforms.net/forms/v2.js';

const InsightArticlePage: React.FC<InsightTemplateProps> = ({
  pageContext,
}) => {
  const { post, relatedPosts } = pageContext;

  const relatedLinksItems = useMemo(
    () => getInsightsItems(relatedPosts, false),
    [relatedPosts],
  );

  const author = useMemo(() => getInsightAuthor(post.blog_post_author), [
    post.blog_post_author,
  ]);

  const contentScripts: string[] = useMemo(
    () => findContentScript(getPostContent(post.post_body, post.post_summary)),
    [post],
  );

  const shouldLoadScript = !!contentScripts.find((item) =>
    item.includes(HBSPT_NAME),
  );
  const isHubSpotLoaded = useScript(HBSPT_JS_PATH, shouldLoadScript);

  const content: string = useMemo(() => {
    let body = getPostContent(post.post_body, post.post_summary);

    if (contentScripts.length) {
      contentScripts.forEach((script) => {
        body = body.replace(`src="${script}"`, '');
      });
    }
    return body;
  }, [post, contentScripts]);

  const Content = useCallback(() => <InnerHTML html={content} />, [content]);

  const ContentComponent = useCallback(() => {
    const scriptsToLoad = contentScripts.filter(
      (item) => !item.includes(HBSPT_NAME),
    );
    if (scriptsToLoad.length) {
      const ContentWithScripts = withScriptLoader(Content, scriptsToLoad);

      return <ContentWithScripts />;
    }
    return <Content />;
  }, [Content, contentScripts]);

  return (
    <>
      <SEO
        title={post.page_title}
        description={post.meta_description}
        imageUrl={getPostImage(post).src}
      />
      <Container>
        <PageSection>
          <ArticleLayout
            date={getDate(post.publish_date)}
            title={post.title}
            subtitle={stripTags(post.post_summary)}
            image={getPostImage(post)}
            author={author}
            content={
              <RevealAnimationWrapper>
                <Box m={['2.5rem 0', null, '5rem 0']}>
                  {(isHubSpotLoaded || !shouldLoadScript) && (
                    <ContentComponent />
                  )}
                </Box>
              </RevealAnimationWrapper>
            }
          />
        </PageSection>
        {relatedLinksItems.length > 0 && (
          <PageSection hasSmallSpacing>
            <ArticlesSection title="Related links" items={relatedLinksItems} />
          </PageSection>
        )}
      </Container>
      <InfoBanner
        items={infoBannerItems}
        modal={{
          title: 'Sign up for email updates',
          onSubmit: noop,
          formId: '',
          portalId: '4072259',
        }}
      />
    </>
  );
};

export default InsightArticlePage;
