import { useEffect, useState } from 'react';

const useScript = (url: string, shouldLoad = true) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (shouldLoad) {
      const script = document.createElement('script');

      script.src = url;
      script.async = true;
      script.onload = () => setLoaded(true);

      console.log('LOAD JS');
      document.body.appendChild(script);
    }
  }, [url, shouldLoad]);

  return loaded;
};

export default useScript;
